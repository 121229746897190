/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://rsms.me/inter/inter.css');
.inter {
	font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
	.inter {
		font-family: 'Inter var', sans-serif;
	}
}

body {
	@apply tracking-tighter;
	background-color: #fffff7;
	font-weight: 430;
	font-style: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

::-moz-selection {
	/* Code for Firefox */
	color: white;
	background: #ff4500;
}

::selection {
	color: white;
	background: #ff4500;
}

.card {
	width: calc(100%);
	height: 20rem;
}

@layer utilities {
	.h-title {
		height: calc(100vh - 38px);
	}
	.h-whole {
		height: -webkit-fill-available;
	}
	.col-1 {
		column-count: 1;
	}
	.col-2 {
		column-count: 2;
	}
	.col-3 {
		column-count: 3;
	}
	.col-4 {
		column-count: 4;
	}
}

@layer base {
	main {
		@apply max-w-screen-lg mx-auto sm:px-2 lg:px-0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply font-normal md:pt-5 md:mb-2 sm:pt-3 sm:mb-1;
	}
	h1 {
		@apply mb-3 tracking-tighter md:text-4xl sm:text-3xl;
	}
	h2 {
		@apply md:text-3xl sm:text-2xl;
	}
	h3 {
		@apply md:text-2xl sm:text-xl;
	}
	h4 {
		@apply md:text-xl sm:text-base;
	}

	p {
		@apply md:mb-4 sm:mb-1 sm:text-sm md:text-base;
	}

	ul,
	li {
		@apply sm:text-sm md:text-base;
	}
}
